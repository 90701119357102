import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
//import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary1: '#84c655',
                secondary1: '#8a8d93'
            },
        },
    },
    icons: {
        iconfont: ['mdi'], // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },
});

<template>
  <v-app>
    <v-app-bar app style="height:100px">
      <div class="boxed-container w-full" style="margin-top:30px">
        <div class="d-flex align-center mx-6 justify-space-between">          
            <div><v-img
              :src="require('@/assets/LogoD.png')"              
              width="150px"            
              alt="logo"
            ></v-img>
          </div>

          <!-- <div style="white-space: pre-line;text-align: center;">
            {{details}}            
          </div> -->
        </div>
      </div>
    </v-app-bar>

    <v-main style="padding-top:110px">
      <div class="app-content-container boxed-container pa-7">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      details: "Oren Chasanmus\n79005570\nCall me for more details"
    };
  },
  methods:{
  },
  created() {
  },
  components: {
  },
};
</script>

<style>
.v-application .pa-7 {
    padding: 10px !important;
  }
  
@media (max-width: 767px) {
  /* Styles for small screens */
  .boxed-container {
    max-width: 98%;
  }

  .v-application .mx-6 {
    margin-left:0 !important;
    margin-right:0 !important;
  }
}

@media (min-width: 768px) {
  /* Styles for large screens */
  .boxed-container {
    max-width: 86%;
  }
}
</style>

<style scoped>
.v-main {
  background-color: #f6f6f0;
}
 
.boxed-container {
  margin-left: auto;
  margin-right: auto;  
} 

</style>

<template>
  <v-app class="tal" v-show="!cloak">
    <v-app-bar app flat>
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6 justify-space-between">
          <router-link to="/dashboard" class="d-flex text-decoration-none">
            <v-img
              :src="require('@/assets/LogoD.png')"
              max-height="60px"
              width="150px"            
              alt="logo"
              contain
              eager
              class="app-logo"
            ></v-img>
            <!-- <span
              style="
                align-self: center;
                padding-top: 10px;
                font-weight: 500;
                letter-spacing: 4px;
                color: #333;
              "
              >Real Es-TECH</span> -->
          </router-link>

          <nav class="mr-16">
            <v-btn plain :to="{ path: '/dashboard' }">
              <v-icon left> mdi-monitor-dashboard </v-icon>
              Dashboard
            </v-btn>
            <v-btn plain :to="{ path: '/search' }">
              <v-icon left> mdi-home-search </v-icon>
              Search
            </v-btn>
            <v-btn plain :to="{ path: '/add-property' }">
              <v-icon left> mdi-database-plus </v-icon>
              <!-- <v-icon>far fa-snowflake</v-icon>               -->
              Add Property
            </v-btn>
            <v-btn plain :to="{ path: '/viewings' }">
              <v-icon left> mdi mdi-eye </v-icon>
              <!-- <v-icon>far fa-snowflake</v-icon>               -->
              Viewings
            </v-btn>
            <!-- <v-btn plain :to="{ path: '/tasks' }">
              <v-icon left> mdi-calendar-check-outline </v-icon>
              Tasks
            </v-btn> -->
          </nav>
          <div>
            <!-- <v-btn plain icon small class="ms-3">
              <v-icon> mdi-bell-outline </v-icon>
            </v-btn> -->
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AppBarUserMenu from "@/components/AppBarUserMenu.vue";

export default {
  data() {
    return {
      isDrawerOpen: true,
      cloak: true,
      digestedPageTitle: "Hello, Oren Chasanmus",
    };
  },
  methods:{
  },
  created() {
    this.cloak = false;
  },
  components: {
    AppBarUserMenu,
  },
};
</script>

<style lang="scss" scoped>
.v-application .pa-6 {
  padding:15px 0 0 0 !important;
}
.v-application .mx-6 {
  margin-left:0 !important;
  margin-right:10px !important;
}

.v-app-bar ::v-deep {
  .v-toolbar__content {
    background-color: #fff;
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }

  .v-btn {
    border: 1px solid transparent;
    &.v-btn--active {
      border-color: inherit;
    }

    .v-btn__content {
      opacity: 1 !important;
    }
  }
}

.v-main {
  background-color: #f6f6f0;
}

.boxed-container {
  max-width: 88%;
  margin-left: auto;
  margin-right: auto;
}

.v-btn--icon.v-size--default {
  width: 38px !important;
  height: 38px !important;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMeta from 'vue-meta'
import '@/assets/css/shared.css'


import "./axios";

Vue.config.productionTip = false;

const toastOptions = {
  timeout: 5000,
  position: "bottom-right",
  hideProgressBar: true,
  transition: "Vue-Toastification__fade",

  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  closeButton: "button",
  icon: true,
};

Vue.use(Toast, toastOptions);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

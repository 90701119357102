import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const state = {
  user: null
};

const getters = {
  user: (state) => {
    //console.log('Getting user from state:',state)
    return state.user;
  }
};

const mutations = {
  user(state, user){
    state.user = user;
  }
};

//changing value of the state -> we call the mutation to change the state, and we get get it via the getters
const actions = {
  user(context, user){
    console.log('Updating user state:',user)
    context.commit('user', user);
  }
};


const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state,
  getters,
  mutations,
  actions,
  modules: {},
});

export default store;

<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          color="secondary"
          class="white--text"
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-account</v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <div
          class="flex-row justify-center ms-3"
          style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1" v-if="user">            
            {{user.first_name + ' ' + user.last_name}}
          </span>
          <small class="text--disabled text-capitalize">(Admin)</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-account-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Inbox -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-email-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge inline color="error" content="2"> </v-badge>
        </v-list-item-action>
      </v-list-item> -->

      <!-- Settings -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-cog-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-logout-variant </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import router from "../router/";
import { mapGetters, mapState } from 'vuex';
import UserService from '../services/UserService';
import axios from "../axios";

export default {
  methods: {
    logout() {
      UserService.Logout()
        .then(response => {          
          //console.log("Logged out")          
        })
        .catch(e => {
          if (e.response) {
            this.$toast.error("Error "+e.response.status + ': '+e.response.data);
            console.log("Error: ",e.response);
          }
        });  

      localStorage.removeItem('token');
      localStorage.removeItem('user');
      //this.$store.dispatch('user', null);

      axios.defaults.headers.common['Authorization'] = '';
      router.push('/login');
    },
  },
  computed: {
    //...mapState(['user'])
    user: function() { 
      //console.log(JSON.parse(localStorage.getItem("user")));
      return JSON.parse(localStorage.getItem('user')) }
    //...mapGetters(['user'])
  }
};
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
.v-badge--dot .v-badge__badge {
  display: none;
}
</style>

<style scoped>
.v-list-item__icon {
  margin: 8px 0;
}

.v-list-item__content {
  padding: 8px 0;
}
</style>

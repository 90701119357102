import http from "../axios";


class UserService {
    async Login(inputs, options){
      let response = await http.post("/user/login", inputs, options);
      //console.log(response);
      return response; 
    }

    async getInfo(agent_id){
      let response = await http.get(`/user/info/${agent_id}`);
      //console.log(response);
      return response; 
    }    

    async Logout(){
      let response = await http.get("/user/logout");
      //console.log(response);
      return response; 
    }

    
}

export default new UserService();
import axios from "axios";
import router from "./router/";

const defaultOptions = {
  baseURL: process.env.VUE_APP_BaseURL,
  headers: {
    "Content-type": "application/json",
    "accept": "*/*"
  }
};

const instance = axios.create(defaultOptions);
export default instance;


function getLocalAccessToken() {
  const accessToken = localStorage.getItem('token');
  return accessToken;
}

async function refresh_token(){
  console.log('Refreshing access token function')
  let response = await instance.get('/user/refresh-token');
  console.log('Refreshing access token function res:',response);
  return response; 
}

let refreshing_token = null;

//Set the AUTH token for any request
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  const token = getLocalAccessToken();
  instance.defaults.withCredentials = true;
  config.headers.Authorization =  token ? `Bearer ${token}` : '';      
  return config;  
},
(error) => {
  // Do something with request error
  console.log('Request Error interceptors ', error.response);  
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
    // Do something with response data
    // Any status code that lie within the range of 2xx cause this function to trigger

    //console.log("Response interceptors");
    return response;
  },
  async (error) => {    
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalConfig = error.config;
    //console.log("Error response: ", error.response)
    //if (error.response && error.response.status === 401 && error.response.data in ("Unable to extract refresh token","Invalid Refresh Token","Token expired")){
    
    if (error.response && error.response.status === 401 && 
      (error.response.data =="Unable to extract refresh token" || error.response.data =="Invalid Refresh Token" || error.response.data =="Token expired" || error.response.data == "Invalid access token or refresh token")){
      console.log("Push to login")
      localStorage.removeItem('token');
      //console.log("Store:",this.$store)
      //this.$store.dispatch('user', null);
      localStorage.removeItem('user');
      
      router.push("/login");      
      return Promise.reject(error);
    }
    
    
    if (error.response && error.response.status === 401 && !originalConfig._retry) {
      console.log("Response interceptors Sending refresh");
      originalConfig._retry = true;

      try {
        console.log("Interceptors Retry for refresh, current refreshing_token:",refreshing_token);

        refreshing_token = refreshing_token ? refreshing_token : refresh_token();
        let res = await refreshing_token;
        console.log("respone refresh:", res)
        refreshing_token = null;
        
        if(res.data.token) {
            //access_token = res.data.token;
            //axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;      
            localStorage.setItem('token', res.data.token);  
            originalConfig.headers.Authorization =  `Bearer ${res.data.token}`;    
            //console.log("refresh token response: ",res);
        }        
        
        return instance(originalConfig);
      } catch (err) {
        console.log("ResponseException: ", err)
        return Promise.reject(err)
      }
    }     
    
    //console.log("UnHandled Error:", error)
    return Promise.reject(error);
});
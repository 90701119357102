<template>
  <component
    :is="
      this.$route.name === 'login' || this.$route.path === '/'
        ? 'layout-blank'
        : this.$route.name === 'prop' ? 'layout-info'
        : 'layout-content'
    "
  >
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from "@/layouts/LayoutBlank.vue";
import LayoutContent from "@/layouts/LayoutContent.vue";
import LayoutInfo from "@/layouts/LayoutInfo.vue";

export default {
  name: "App",
  /*
  metaInfo: {
      
          title: "Real Estech - Real Estate Platform",
          meta: [
              { name: 'description', content:  'Real Estate Platform'},
              { key: 'og:title', name: 'og:title', content: "Real Estate"},
              { key: 'og:description', name: 'og:description', content: "Real Estate Platform"},
              { key: 'og:site_name', name: 'og:site_name', content: 'Real Estech'},
              { key: 'og:type', name: 'og:type', content: 'website'},    
              //{ name: 'robots', content: 'index,follow'} 
          ]
      
  },
  */
  data: () => ({
  }),
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutInfo
  }
};
</script>

<style>
img {
  pointer-events: none;
}
.theme--light.v-application .text--primary {
  color: #555 !important;
}
</style>